import React, { useState, useCallback, useMemo } from "react";

import toast from "react-hot-toast";
import axios from "axios";
import server from "../../utils/serverConfig";
import { XIcon, CirclePlusIcon } from "lucide-react";
import { debounce } from "lodash";
import NewPushStockRebalanceCard from "../StrategySection/NewPushStockRebalanceCard";

const RebalanceModal = ({
  closeRebalanceModal,
  fileName,
  data,
  strategyDetails,
  getStrategyDetails,
}) => {
  const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
  const [adviceEntries, setAdviceEntries] = useState(data);
  const [saveButton, setSaveButton] = useState(false);

  const handleAddAdviceEntry = () => {
    const newEntry = {
      symbol: "",
      exchange: "",
      segment: "EQUITY",
      inputValue: "",
      symbols: [],
      value: "",
      isNew: true,
    };
    setAdviceEntries((prevEntries) => {
      const updatedEntries = [newEntry, ...prevEntries];
      return updatedEntries;
    });
    setSaveButton(false);
  };

  const handleRemoveAdviceEntry = (index) => {
    setAdviceEntries((prevEntries) => {
      if (prevEntries.length <= 0) {
        return prevEntries;
      }
      const updatedEntries = prevEntries.filter((_, i) => i !== index);

      return updatedEntries;
    });
    setSaveButton(false);
  };

  const handleAdviceChange = (index, field, value) => {
    setAdviceEntries((prevEntries) => {
      const updatedEntries = prevEntries.map((entry, i) => {
        if (i === index) {
          const isNewValue = entry.isNew || entry[field] !== value / 100;
          return {
            ...entry,
            [field]: isNewValue ? value : value / 100,
            isNew: true,
          };
        }
        return entry;
      });

      return updatedEntries;
    });
  };

  const handleSymbolSelect = (index, symbol, exchange) => {
    const updatedEntries = adviceEntries.map((entry, i) =>
      i === index
        ? {
            ...entry,
            symbol: symbol,
            symbols: [],
            inputValue: symbol,
            exchange: exchange,
          }
        : entry
    );
    setAdviceEntries(updatedEntries);
  };

  const handleIncrement = (index) => {
    setAdviceEntries((prevEntries) => {
      const updatedEntries = [...prevEntries];
      const entry = updatedEntries[index];
      const currentValue = parseFloat(entry.value) || 0;
      const incrementValue = entry.isNew ? 1 : 0.01;
      updatedEntries[index] = {
        ...entry,
        value: (currentValue + incrementValue).toString(),
      };
      return updatedEntries;
    });
  };

  const handleDecrement = (index) => {
    setAdviceEntries((prevEntries) => {
      const updatedEntries = [...prevEntries];
      const entry = updatedEntries[index];
      const currentValue = parseFloat(entry.value) || 0;
      const decrementValue = entry.isNew ? 1 : 0.01;
      updatedEntries[index] = {
        ...entry,
        value: Math.max(0, currentValue - decrementValue).toString(),
      };
      return updatedEntries;
    });
  };

  const fetchSymbols = async (index, inputValue) => {
    const data = JSON.stringify({
      symbol: inputValue,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${server.ccxtServer.baseUrl}angelone/get-symbol-name-exchange`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      setAdviceEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index ? { ...entry, symbols: response.data.match } : entry
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedFetchSymbols = useCallback(
    debounce((index, value) => {
      fetchSymbols(index, value);
    }, 300),
    []
  );

  const handleInputChange = (index, value) => {
    setAdviceEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, inputValue: value, symbol: value } : entry
      )
    );

    if (value.length >= 3) {
      debouncedFetchSymbols(index, value);
    }
  };

  const calculateTotalAllocation = () => {
    return adviceEntries?.reduce((total, entry) => {
      // Only multiply by 100 if the entry is not new
      const entryValue = entry?.isNew
        ? parseFloat(entry.value)
        : parseFloat(entry.value * 100);
      return total + (entryValue || 0);
    }, 0);
  };

  const totalAllocation = useMemo(
    () => calculateTotalAllocation(),
    [adviceEntries]
  );

  const isFormValid = () => {
    return (
      totalAllocation === 100 &&
      adviceEntries.every(
        (entry) => entry.symbol && entry.value && entry.value > 0
      )
    );
  };

  let convertedData = { model_portfolio: [] };

  adviceEntries?.forEach((item) => {
    const symbol = item?.symbol;
    let value = item?.isNew
      ? parseFloat(item?.value / 100).toFixed(2)
      : parseFloat(item?.value).toFixed(2);
    const exchange = item.exchange;

    convertedData.model_portfolio.push({ symbol, value, exchange });
  });

  console.log(JSON.stringify(convertedData));

  const [minimumPortfolioAmount, setMinimumPortfolioAmount] = useState();
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const handleMinimumPortfolioAmount = () => {
    setIsLoadingSave(true);
    let config = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}rebalance/minimum-portfolio-amount-new`,
      headers: {
        "Content-Type": "application/json",
      },
      data: convertedData,
    };

    axios
      .request(config)
      .then((response) => {
        setMinimumPortfolioAmount(response.data);
        setSaveButton(true);
        setIsLoadingSave(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setIsLoadingSave(false);
      });
  };

  const strategyId = strategyDetails?._id;
  const modelName = fileName //?.replaceAll(/_/g, " ");  // removing regex for passing correct variable.

  const transformedAdviceEntries = adviceEntries?.map((entry) => ({
    ...entry,
    value: entry.isNew ? entry.value / 100 : entry.value,
  }));
  const newAdviceEntries = transformedAdviceEntries;
  const totalInvestmentValue =
    minimumPortfolioAmount?.min_investment_amount.toFixed(2);

  const [isLoading, setIsLoading] = useState(false);

  function getNextRebalanceDate(frequency) {
    const today = new Date();
    let nextRebalance = new Date(today); // Start with today's date

    switch (frequency) {
      case "Every Day":
        nextRebalance.setDate(today.getDate() + 1);
        break;

      case "Every Week":
        nextRebalance.setDate(today.getDate() + 7);
        break;

      case "Every Month":
        nextRebalance.setMonth(today.getMonth() + 1);
        break;

      case "Every Quarter":
        nextRebalance.setMonth(today.getMonth() + 3);
        break;

      case "Every Year":
        nextRebalance.setFullYear(today.getFullYear() + 1);
        break;

      case "Need Basis":
        // If it's need basis, randomly add 2 or 3 days
        const randomDays = Math.floor(Math.random() * 2) + 2; // Will give either 2 or 3
        nextRebalance.setDate(today.getDate() + randomDays);
        break;

      default:
        console.log("Unknown frequency.");
        return null;
    }

    return nextRebalance;
  }

  const nextRebalanceDate = getNextRebalanceDate(strategyDetails?.frequency);
  const handleModelPortfolio = (
    id,
    modelName,
    newAdviceEntries,
    totalInvestmentvalue
  ) => {
    setIsLoading(true);

    const data = JSON.stringify({
      modelName: modelName,
      newAdviceEntries: newAdviceEntries,
      totalInvestmentvalue: totalInvestmentvalue,
      nextRebalanceDate: nextRebalanceDate,
    });

    let config = {
      method: "put",
      url: `${server.server.baseUrl}api/model-portfolio/next-push/update-strategy/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        let whatsappData = JSON.stringify({
          advisor: advisorTag,
          modelName: modelName,
        });

        let whatsappConfig = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}comms/new-rebalance-push`,
          headers: {
            "Content-Type": "application/json",
          },
          data: whatsappData,
        };

        await axios.request(whatsappConfig);
        setIsLoading(false);
        toast.success("Strategy updated successfully", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "16px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });

        setTimeout(() => {
          getStrategyDetails();
          closeRebalanceModal();
        }, 3000);
      })
      .catch((error) => {
        console.error("Error updating strategy:", error);
        toast.error("Error in updating strategy!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
        setIsLoading(false);
      });
  };

  const handleUpdateStrategy = () => {
    handleModelPortfolio(
      strategyId,
      modelName,
      newAdviceEntries,
      totalInvestmentValue
    );
  };

  console.log("newAdviceEntries", newAdviceEntries);
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50 px-2 ">
      <div className="relative ">
        <div
          className="absolute right-2 top-6  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer "
          onClick={closeRebalanceModal}
        >
          <XIcon className="w-6 h-6 mr-2  " />
        </div>

        <div className="flex flex-col w-full lg:h-[650px] lg:w-[820px]   bg-white  border-[#000000]/20 rounded-md pt-6 overflow-hidden">
          <div className="flex  w-full pb-4 text-[18px] lg:text-[22px]  text-[#000000] font-sans font-bold px-8 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] ">
            Add New Rebalance
          </div>

          <div className="px-2 lg:px-8 py-4 bg-white sticky top-0 z-50 w-full items-center grid grid-cols-8 lg:grid-cols-10 h-[50px]">
            <div className="col-span-1 flex flex-row justify-center ">
              <CirclePlusIcon
                className="w-4 h-4 md:w-6 md:h-6    text-green-600 cursor-pointer"
                onClick={handleAddAdviceEntry}
              />
            </div>
            <div className="flex items-center text-center text-[11px] md:text-[14px] text-[#000000]/60 font-poppins font-medium col-span-3 lg:col-span-4">
              Stock Name
            </div>
            <div className="flex items-center justify-center text-center text-[11px] md:text-[14px] text-[#000000]/60 font-poppins font-medium col-span-2 lg:col-span-2">
              Allocation (in%)
            </div>
            <div className="flex items-center justify-center text-center text-[11px] md:text-[14px] text-[#000000]/60 font-poppins font-medium col-span-2 lg:col-span-3">
              Investment Value
            </div>
          </div>

          <div className="flex flex-col space-y-3 h-[540px]  overflow-auto custom-scroll-2 px-2 lg:px-8 py-4 ">
            {adviceEntries?.length > 0 &&
              adviceEntries?.map((entry, index) => {
                return (
                  <NewPushStockRebalanceCard
                    key={index}
                    handleRemoveAdviceEntry={handleRemoveAdviceEntry}
                    handleAddAdviceEntry={handleAddAdviceEntry}
                    adviceEntries={adviceEntries}
                    index={index}
                    handleInputChange={handleInputChange}
                    handleSymbolSelect={handleSymbolSelect}
                    entry={entry}
                    handleAdviceChange={handleAdviceChange}
                    handleIncrement={handleIncrement}
                    handleDecrement={handleDecrement}
                    minimumPortfolioAmount={minimumPortfolioAmount}
                  />
                );
              })}
          </div>

          {/* <div className="flexflex-col w-full  shadow-[0px_-4px_4px_0px_#0000000f] py-4 px-8 "> */}
          <div className="flex flex-col w-full  shadow-[0px_-4px_4px_0px_#0000000f] pb-4">
            {totalAllocation > 100 && (
              <div className="text-rose-600 font-semibold px-8 pt-2">
                Total stock allocation cannot exceed 100%.
              </div>
            )}
            <div className="grid grid-cols-3 gap-x-4 gap-y-4 lg:gap-y-0 lg:gap-x-0 lg:grid-cols-5 border-b-[1px] border-[#000000]/20 pt-2 pb-4">
              <div className="flex flex-col lg:items-center space-y-1">
                <h4 className="text-[12px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium">
                  Total Stocks
                </h4>
                <p className=" lg:w-[120px] text-[12px] lg:text-[15px] text-center text-[#000000] font-poppins font-semibold bg-gray-100 rounded-md py-3 px-5">
                  {adviceEntries?.length}
                </p>
              </div>

              <div className="flex flex-col lg:items-center space-y-1">
                <h4 className="text-[12px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium">
                  Total Allocation
                </h4>
                <p className=" lg:w-[120px] text-[12px] lg:text-[15px] text-center text-[#000000] font-poppins font-semibold bg-gray-100 rounded-md py-3 px-5">
                  {parseFloat(calculateTotalAllocation()).toFixed(2)}%
                </p>
              </div>
              <div className="flex flex-col lg:items-center space-y-1">
                <h4 className="text-[12px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium">
                  Total Investment
                </h4>
                <p className=" lg:w-[120px] text-[12px] lg:text-[15px] text-center text-[#000000] font-poppins font-semibold bg-neutral-100 rounded-md py-3 px-5">
                  ₹{minimumPortfolioAmount?.min_investment_amount.toFixed(2)}
                </p>
              </div>
            </div>

            <div className="pt-2 flex justify-end space-x-5 pr-[20px] ">
              {saveButton === true ? (
                <button
                  className=" disabled:bg-[#000000]/50 bg-[#000000] rounded-[4px] py-2 px-3 lg:py-3 lg:px-8 text-[14px] lg:text-[16px] text-center text-[#ffffff] font-poppins font-medium disabled:cursor-not-allowed"
                  onClick={handleUpdateStrategy}
                >
                  {isLoading === true ? (
                    <span className="flex flex-row justify-center">
                      <svg
                        className="h-7 w-7 text-[#ffffff] animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </span>
                  ) : (
                    " Push Rebalance"
                  )}
                </button>
              ) : (
                <button
                  disabled={!isFormValid()}
                  className=" disabled:bg-[#000000]/50 bg-[#000000] rounded-[4px] py-2 px-3 lg:py-3 lg:px-8 text-[14px] lg:text-[16px] text-center text-[#ffffff] font-poppins font-medium disabled:cursor-not-allowed"
                  onClick={handleMinimumPortfolioAmount}
                >
                  {isLoadingSave === true ? (
                    <span className="flex flex-row justify-center">
                      <svg
                        className="h-7 w-7 text-[#ffffff] animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </span>
                  ) : (
                    " Calculate"
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RebalanceModal;
