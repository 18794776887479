import React , {useState} from "react";
import { XIcon } from "lucide-react";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import { motion, AnimatePresence } from "framer-motion";
import { DatePicker } from "../../components/ui/date-picker";
import { ClientFormFields } from "./ClientFormFields";

const ccxtUrl = process.env.REACT_APP_CCXT_SERVER_API_URL;
const serverUrl = process.env.REACT_APP_NODE_SERVER_API_URL;

const server = {
  ccxtServer: {
    baseUrl: ccxtUrl,
  },
  server: {
    baseUrl: serverUrl,
  },
  // server: {
  //   baseUrl: `http://localhost:8002/`,
  // },
};

const AddClientModal = ({
  userId,
  closeAddClientModal,
  setNewClientData,
  newClientData,
  setAddClientModal,
  getAllClientsData,
  groupName,
  creationDate,
  setCreationDate,
}) => {


  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e, error) => {
    const { name, value } = e.target;
    setNewClientData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // CHANGE: Update formErrors state
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };


  const handleAddNewClient = async () => {
    const allData = {
      clientId: newClientData.clientId,
      clientName: newClientData.clientName,
      email: newClientData.clientEmail,
      phone: newClientData.phone,
      groups: ["All Client", groupName],
      location: newClientData.location,
      telegram: newClientData.telegram,
      pan: newClientData.pan,
      creationDate: new Date(creationDate).toISOString(),
      subscriptions: [],
    };

    try {
      const response = await fetch(
        `${server.ccxtServer.baseUrl}comms/add-new-client-to-groups`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId,
            clientData: allData,
          }),
        }
      );

      if (response.ok) {
        setNewClientData({
          clientId: uuidv4().slice(0, 7),
          clientName: "",
          clientEmail: "",
          phone: "",
          location: "",
          telegram: "",
          pan: "",
        });

        setAddClientModal(false);
        getAllClientsData(userId);
        toast.success("Client added successfully!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
        });
      } else {
        throw new Error("Failed to add client");
      }
    } catch (error) {
      console.error("Error adding client:", error);
      toast.error("Failed to add client", {
        duration: 3000,
        className: "bg-white text-red-600 font-medium",
      });
    }
  };
    // CHANGE: Update isFormValid to include error checks
    const isFormValid =
    newClientData.clientId &&
    newClientData.clientName &&
    newClientData.clientEmail &&
    newClientData.phone &&
    !formErrors.clientEmail &&
    !formErrors.phone &&
    newClientData.phone.length === 10;


  return (
    <AnimatePresence>
      <div className="fixed inset-0 flex items-center justify-center bg-black/75 z-50">
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          className="relative w-full max-w-3xl mx-4"
        >
          <div className="relative bg-white rounded-lg shadow-xl overflow-hidden">
            <button
              onClick={closeAddClientModal}
              className="absolute right-4 top-4 p-1 rounded-full hover:bg-gray-100 transition-colors"
            >
              <XIcon className="w-6 h-6 text-gray-500" />
            </button>

            <div className="px-6 py-4 border-b border-gray-200">
              <h2 className="text-xl font-semibold text-gray-900">
                Add New Client
              </h2>
            </div>

            <div className="p-6">
              <ClientFormFields
                data={newClientData}
                onInputChange={handleInputChange}
                creationDate={creationDate}
                setCreationDate={setCreationDate}
              />

              <div className="mt-6 flex justify-end space-x-3">
                <button
                  onClick={closeAddClientModal}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Cancel
                </button>
                <button
                  onClick={handleAddNewClient}
                  // disabled={
                  //   !newClientData.clientId ||
                  //   !newClientData.phone ||
                  //   !newClientData.clientName ||
                  //   !newClientData.clientEmail
                  // }
                  disabled={!isFormValid}

                  className="px-4 py-2 text-sm font-medium text-white bg-black rounded-md hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 disabled:bg-gray-300 disabled:cursor-not-allowed"
                >
                  Add Client
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default AddClientModal;
