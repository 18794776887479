import React, { useState, useEffect } from "react";
import { XIcon } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import toast from "react-hot-toast";
import { ClientFormFields } from "./ClientFormFields";

const EditClientModal = ({ client, onClose, onUpdate }) => {
  const [editedClient, setEditedClient] = useState(client);
  const [creationDate, setCreationDate] = useState(
    new Date(client.creationDate)
  );

  // useEffect(() => {
  //   const handleEscapeKey = (e: KeyboardEvent) => {
  //     if (e.key === 'Escape') {
  //       onClose();
  //     }
  //   };

  //   document.addEventListener('keydown', handleEscapeKey);
  //   return () => document.removeEventListener('keydown', handleEscapeKey);
  // }, [onClose]);
  useEffect(() => {
    const handleEscapeKey = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscapeKey);
    return () => document.removeEventListener("keydown", handleEscapeKey);
  }, [onClose]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedClient((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = async () => {
    // try {
      await onUpdate({
        ...editedClient,
        creationDate: creationDate.toISOString(),
      });
    //   toast.success("Client updated successfully!", {
    //     duration: 3000,
    //     style: {
    //       background: "white",
    //       color: "#1e293b",
    //       maxWidth: "500px",
    //       fontWeight: 600,
    //       fontSize: "13px",
    //       padding: "10px 20px",
    //     },
    //   }
    
    // );
      onClose();
    } 
    
  //   catch (error)
  
  //   {
  //     toast.error("Failed to update client", {
  //       duration: 3000,
  //       style: {
  //         background: "white",
  //         color: "#1e293b",
  //         maxWidth: "500px",
  //         fontWeight: 600,
  //         fontSize: "13px",
  //         padding: "10px 20px",
  //       },
  //     }
    
  //   );
  //   }
  // };

  return (
    <AnimatePresence>
      <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 z-50 p-4 ">
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          transition={{ duration: 0.2 }}
          className="relative w-full max-w-3xl mx-4 max-h-[95vh] my-4 sm:my-0 bg-white rounded-lg overflow-auto "
        >
          <div className="relative bg-white rounded-lg shadow-xl overflow-hidden">
            <button
              onClick={onClose}
              className="absolute right-4 top-4 p-1 rounded-full hover:bg-gray-100 transition-colors"
              aria-label="Close modal"
            >
              <XIcon className="w-6 h-6 text-gray-500" />
            </button>

            <div className="px-6 py-4 border-b border-gray-200">
              <h2 className="text-xl font-semibold text-gray-900">
                Edit Client Details
              </h2>
            </div>

            <div className="p-6">
              <ClientFormFields
                data={{
                  clientId: editedClient.clientId,
                  clientName: editedClient.clientName,
                  clientEmail: editedClient.email,
                  phone: editedClient.phone,
                  location: editedClient.location,
                  telegram: editedClient.telegram,
                  pan: editedClient.pan,
                }}
                onInputChange={handleInputChange}
                creationDate={creationDate}
                setCreationDate={setCreationDate}
              />

              <div className="mt-6 flex justify-end space-x-3">
                <button
                  onClick={onClose}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Cancel
                </button>
                <button
                  onClick={handleUpdate}
                  disabled={
                    !editedClient.email ||
                    !editedClient.clientName ||
                    !editedClient.phone
                  }
                  className="px-4 py-2 text-sm font-medium text-white bg-black rounded-md hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 disabled:bg-gray-300 disabled:cursor-not-allowed"
                >
                  Update Client
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default EditClientModal;
