import React , {useState} from "react";
import { DatePicker } from "../ui/date-picker";

const InputField = ({ label, id, name, type = "text", value, onChange, required = false, placeholder }) => {

  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { value } = e.target;

    if (type === "tel") {
      // Allow only numbers and limit to 10 digits
      const numericValue = value.replace(/\D/g, "").slice(0, 10);
      if (numericValue.length < 10) {
        setError("Phone number must be 10 digits.");
      } else if (numericValue.length > 10) {
        setError("Phone number cannot exceed 10 digits.");
      } else {
        setError("");
      }
      onChange({ target: { name, value: numericValue } });
    } else if (type === "email") {
      // Check email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        setError("Please enter a valid email address.");
      } else {
        setError("");
      }
      onChange(e);
    } else {
      onChange(e);
    }

    // CHANGE: Pass the error state up to the parent component
    onChange(e, error || (type === "tel" && value.length !== 10 ? "Phone number must be 10 digits." : ""));
  };



  return (


  
  <div className="flex flex-col space-y-1">
    <label htmlFor={id} className="text-sm font-medium text-gray-700">
      {label}
      {required && <span className="ml-1 text-red-500">*</span>}
    </label>
    <input
      id={id}
      name={name}
      type={type}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
      />
            {error && <span className="text-sm text-red-500">{error}</span>}

      </div>
    );
  };
export const ClientFormFields = ({ data, onInputChange, creationDate, setCreationDate }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
    <InputField
      label="Client ID"
      id="clientId"
      name="clientId"
      value={data.clientId}
      onChange={onInputChange}
      required
      placeholder="Enter Client ID"
    />

    <InputField
      label="Email"
      id="clientEmail"
      name="clientEmail"
      type="email"
      value={data.clientEmail}
      onChange={onInputChange}
      required
      placeholder="Enter Email"
    />

    <InputField
      label="Name"
      id="clientName"
      name="clientName"
      value={data.clientName}
      onChange={onInputChange}
      required
      placeholder="Enter Name"
    />

    <InputField
      label="Phone"
      id="phone"
      name="phone"
      type="tel"
      value={data.phone}
      onChange={onInputChange}
      required
      placeholder="Enter Phone"
    />

    <InputField
      label="Location"
      id="location"
      name="location"
      value={data.location}
      onChange={onInputChange}
      placeholder="Enter Location"
    />

    <InputField
      label="Telegram"
      id="telegram"
      name="telegram"
      value={data.telegram}
      onChange={onInputChange}
      placeholder="Enter Telegram ID"
    />

    <InputField
      label="KYC"
      id="pan"
      name="pan"
      value={data.pan}
      onChange={onInputChange}
      placeholder="Enter KYC"
    />

    <div className="flex flex-col space-y-1">
      <label className="text-sm font-medium text-gray-700">
        Creation Date
        <span className="ml-1 text-red-500">*</span>
      </label>
      <DatePicker
        date={creationDate}
        setDate={setCreationDate}
        placeholder="Choose Creation Date"
        className="w-full"
      />
    </div>
  </div>
);